@import 'colors';

body {
  margin: 0;
  font-family: Graphik, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.flex {
  display: flex;
}
.flex-auto {
  flex: auto;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

// Fonts
$extralight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

@font-face {
  font-family: 'Graphik';
  src: url('/webfonts/graphik/Graphik-Extralight-Web.woff');
  font-weight: $extralight;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/webfonts/graphik/Graphik-Light-Web.woff');
  font-weight: $light;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/webfonts/graphik/Graphik-Regular-Web.woff');
  font-weight: $regular;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/webfonts/graphik/Graphik-Medium-Web.woff');
  font-weight: $medium;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/webfonts/graphik/Graphik-Semibold-Web.woff');
  font-weight: $semibold;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/webfonts/graphik/Graphik-Bold-Web.woff');
  font-weight: $bold;
  font-style: normal;
}

$font-family: 'Graphik', sans-serif;
$font-awesome: 'Font Awesome 5 Pro', sans-serif;
