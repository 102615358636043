@import '../../styles/colors';

.dialog-payment-footer {
  color: #a1a1a1;
  a {
    text-decoration: none;
    cursor: pointer;
    color: $color-accent;
    padding-left: 4px;
  }
}
